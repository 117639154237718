<template lang="">
    <div class="d-flex flex-column position-fixed top-0 start-0 vh-100 w-100 bg-canvas">
        <HeaderMain></HeaderMain>

        <!-- subnavigation -->
        <div class="container-fluid border-bottom bg-white shadow-sm position-relative py-2 px-4 d-flex align-items-center">
            <span>
                <ButtonCircle class="btn-primary" icon="navigate_before" :onClick="$router.back"></ButtonCircle>

                <nav class="d-inline-block ms-3" style="--bs-breadcrumb-divider: '›';">
                    <ol class="breadcrumb mb-0 fw-500 font-size-18 lh-lg">
                        <li class="breadcrumb-item">Workbooks</li>
                    </ol>
                </nav>
            </span>

            <span class="flex-fill"></span>

            <span>
                <ButtonCircle class="" icon="file_upload"></ButtonCircle>
                <ButtonCircle class="ms-2" icon="library_add"></ButtonCircle>
                <ButtonMain class="ms-2 rounded-pill btn-sm fs-6" icon="add" :onClick="createWorkbook">New Workbook</ButtonMain>
            </span>
        </div>

        <div class="container-fluid overflow-scroll flex-fill w-100 py-5">
            <div class="pb-5">
                <div class="mx-auto" :style="{width: variableContainerWidth + 'px'}">
                    <div class="" v-for="(workbook, index) in workbooks" :key="index" >
                        <WorkbookCard class="mb-4 float-start mx-3"
                            :data="workbook"
                            :options="options"
                            :onClick="editWorkbook"
                            :onOption="onOption"
                            :width="current.content.cardWidth"
                            ></WorkbookCard> 
                    </div>

                    <div v-if="workbooks.length === 0 && mounted" class="col-12 text-center">
                        <h1>Create your first Workbook</h1>
                        <a class="btn btn-outline-primary py-0 me-2" href="#" @click.prevent="createWorkbook()">Create</a>
                    </div>
                </div>
            </div>

        </div>
        <FooterMain class=""></FooterMain>
    </div>
</template>


<script>

import WorkbookCard from "./widgets/WorkbookCard.vue";

export default {
    components: {
        WorkbookCard
    },

    data() {
        return {
            collection: [],
            mounted: false, 
            current: {
                content: {
                    width: document.body.offsetWidth,
                    cardWidth: 280
                }
            }
        }
    },

    computed: {
        workbooks() {
            return this.collection;
        },

		options() {
			return [
                this.$ui.helper.options.EDIT,
                this.$ui.helper.options.DETAILS,
                this.$ui.helper.options.DOWNLOAD,
                this.$ui.helper.options.DIVIDER,
                this.$ui.helper.options.DELETE,
            ]
		}, 

        variableContainerWidth() {
            let cardWidth = this.current.content.cardWidth + 32;
            let width = Math.floor(this.current.content.width / cardWidth) * cardWidth;
            return width
        }
    },

    methods: {


        // openWorkbook(data) {
        //     let name = data.name;
		// 	let path = this.$root.links.WORKBOOKS + '/run/' + name;
		// 	this.$root.$router.push( {path: path} );
        // },



        /**
         * Edit Strategy details
         * 
         * @param {Object} data
         * @param {Object} data.ID
         * @param {Object} data.title
         * @param {Object} data.name
         * @param {Object} data.content
         * @param {Object} data.excerpt
         * @param {Object} data.kind
         * 
         */
        createWorkbook(data) {
            let modal = this.$root.modal({
                title: 'Create Workbook',
                context: data,
                okay: {
                    label: 'CREATE',
                    visible: true,
                    disabled: false,
                    callback: async (component) => {
                        
                        let request = {
                            post_title: component.data.title,
                            post_excerpt: component.data.excerpt, 
                            meta_input: {
                                color: component.data.color
                            }
                        };


                        console.log('createWorkbook', request);

						let response = await this.$root.$store.dispatch('createWorkbook', request);

                        await this.refresh(true);

						this.$root.toast(`The workbook "${component.data.title}" has been created.`);
						
						this.$root.notify({
							title: 'Confirm',
							type: 'confirm',
							message: 'Would you like to open this workbook (' + response.title + ')?',
							callback: (okay)=> {
                                let path = `/workbooks/${response.name}`;
								okay && this.$root.$router.push( {path: path} );
							}
						});

                    }
                }, 

            }, 'ModalContentWorkbook');

            setTimeout(() => {
				/**
				 * Setup opportunity data as copy to prevent Vue reactivity from
				 * editing data in $store.state as user types-in in form
				 */
				let content = modal.content();
                // content.data.title = data.title;
                // content.data.excerpt = data.excerpt;
                // content.data.opportunity_id = data.opportunity_id;
				content.validate = function() {
					let isValid = false;
					if('title' in this.data && this.data.title?.length > 0) {
						isValid = true;
					}
					if(!isValid) {
						alert('Please fill out all required fields');
					}
					return isValid;
				};
            }, 0);



			return modal;
        },


        deleteWorkbookIntent(workbook) {
            this.$root.notify({
                title: 'Confirm',
                type: 'confirm',
                message: 'Would you want to delete this workbook (' + workbook.title + ')?',
                callback: (okay)=> {
                    okay && this.deleteWorkbook(workbook);
                }
            });
        },


        async deleteWorkbook(workbook) {
            let response = await this.$store.dispatch('deleteWorkbook', workbook);
            await this.refresh(true);
            this.$root.toast(`The workbook "${workbook.title}" has been deleted.`);
            response
        }, 



        downloadWorkbookIntent(workbook) {
            this.$root.notify({
                title: 'Confirm',
                type: 'confirm',
                message: 'Would you want to download this workbook (' + workbook.title + ')?',
                callback: (okay)=> {
                    okay && this.downloadWorkbook(workbook);
                }
            });
        },


        async downloadWorkbook(workbook) {
			this.$store.commit('SET_LOADING_STATE', true);
			let data = await this.$store.getters.wp.getWorkbook(workbook.ID);
			let content = await this.$store.dispatch('objectToAppc', data);

			this.$root.download({
				content: content,
				fileName: workbook.name + '.appc',
				mime: 'text/plain',
				callback: ()=> {
                    this.$root.toast(`The workbook "${workbook.title}" has been downloaded.`);
                }
			});

			this.$store.commit('SET_LOADING_STATE', false);
        }, 




        /**
         * @params {Object} workbook
         * @params {Object} workbook.ID
         * @params {Object} workbook.title
         * @params {Object} workbook.name
         * @params {Object} workbook.excerpt
         */
        editWorkbookDetailsIntent(workbook) {
            let modal = this.$root.modal({
                title: 'Update Workbook',
                context: workbook,
                okay: {
                    label: 'UPDATE',
                    visible: true,
                    disabled: false,
                    callback: async (component) => {
                        let request = {
                            ID: workbook?.ID,
                            post_title: component.data.title,
                            post_excerpt: component.data.excerpt,
                            meta_input: {
                                color: component.data.color
                            }
                        };
                        this.editWorkbookDetails(request);
                    }
                }, 

            }, 'ModalContentWorkbook');

            setTimeout(() => {
				let content = modal.content();
                content.data.title = workbook.title;
                content.data.excerpt = workbook.excerpt;
                content.data.color = workbook?.meta?.color || '';
				content.validate = function() {
					let isValid = false;
					if('title' in this.data && this.data.title?.length > 0) {
						isValid = true;
					}
					if(!isValid) {
						alert('Please fill out all required fields');
					}
					return isValid;
				};
            }, 0);
        },

        /**
         * @params {Object} request
         * @params {Object} request.ID
         * @params {Object} request.post_title
         * @params {Object} request.post_excerpt
         */
        async editWorkbookDetails(request) {
            await this.$root.$store.dispatch('updateWorkbook', request);
            await this.refresh(true);
            this.$root.toast(`The workbook "${request.post_title}" has been updated.`);
        },



        /**
         * Go to edit Workbook window
         * 
         * 
         * @params {Object} workbook
         * @params {Object} workbook.ID
         * @params {Object} workbook.title
         * @params {Object} workbook.name
         * @params {Object} workbook.excerpt
         */
        editWorkbook(workbook) {

            let path = this.$root.links.WORKBOOKS + '/' + workbook.name;
            this.$router.push( { path: path } );

            // await this.$root.$store.dispatch('updateWorkbook', request);
            // await this.refresh(true);
            // this.$root.toast(`The workbook "${request.post_title}" has been updated.`);
        },




        /**
         * Download, delete, or show details of a workbook based on
         * a selection from a dropdown menu.
         * 
         * @param option
         * @param option.name
         * @param option.title
         * @param option.type
         * @param option.icon
         * @param option.disabled
         * @param option.classes
         * 
         * @param workbook
         * @param workbook.ID
         * @param workbook.name
         * @param workbook.title
         * @param workbook.excerpt
         * 
         */
        onOption(option, workbook) {
            let name = option?.name;

            switch (name) {
                case 'edit':
                    this.editWorkbook(workbook);
                    break;


                case 'details':
                    this.editWorkbookDetailsIntent(workbook);
                    break;
                    
                case 'download':
                    this.downloadWorkbookIntent(workbook);
                    break;
                    
                case 'delete':
                    this.deleteWorkbookIntent(workbook);
                    break;
            
                default:
                    break;
            }
        },


        async refresh(forceReload=false) {
            this.collection = await this.$store.dispatch('getWorkbooks', forceReload);
        }
    }, 


    async mounted() {
        (this.collection.length === 0) && (await this.refresh(false));
        this.mounted = true;

        window.addEventListener('resize', () => {
            this.current.content.width = document.body.offsetWidth;
        }, {one: true});
    }
};
</script>

<style scoped>
.workbook-options {
    opacity: 0;
    transition: all 0.3s;
    transition-delay: 0.1s;
}
.card:hover .workbook-options {
    opacity: 1;
}

.card .card-body small {
    min-height: 42px;
}

.card .initials {
    color: rgba(0,0,0,.2);
    /* text-shadow: inset 0 2px 1px rgba(0,0,0,.5); */
}

.card .btn.bg-light:hover {
    background-color: #e9ecef !important;
    /* border: solid 1px #adb5bd; */
}
</style>